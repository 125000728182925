.card_autor {
    font-size: xx-small;
    background-color: black;
    color: #fff;
    width: 100%;
    border: 1px;
    padding: 2px 1px;
    margin: -4px 0px;
}
.botones{
    padding-bottom: 30px;
    display: block;
    height: auto;
}

.menu_movil {
    display:none;
}
@media only screen and (max-width: 767px) {
    .menu_movil {
        display: block;
    }
}
