.c_menu_lateral {
    .buttons_group {
        display: none;
    }
    ion-label {
        padding-left: 10px;
        font-family: 'Bebas Neue Regular';
        color: red;
    }
    .btn_menu_home {
        color: var(--ion-color-secondary);
        font-family: 'Bebas Neue Regular';
        padding-left: 10px;
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 767px) {
    .c_menu_lateral {
        .buttons_group {
            display: block;
        }
        .centro_pagina {
            display: none !important;
        }
    }
    .lateral {
        width: 100vw;
        height: 100vh;
        background-image: url('/assets/images/fondo_pc.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        --background: transparent;
    }
    .ionFabSocial {
        margin-right: 108px;
        padding-top: 5px;
        display: block;
    }
    .social_barra {
        padding-top: calc(0vh + 10px);
        float: right;
        display: inline-flex;
    }
    .menu_lateral_pc {
        display: none;
    }
    .buttons_group {
        width: 100vw;
        height: 136px;
        position: fixed;
        top: calc(100vh - 260px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
    }
}

.social_barra {
    display: none;
}

.social_rede_pk {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
    margin: 10px 5px;
}

.icon_facebook {
    color: #3b5998;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}

.icon_twiter {
    color: #1da1f2;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}

.icon_instagram {
    color: #517fa4;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}

.icon_youtube {
    color: red;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}