.icon_settings {
    position: relative;
    float: right;
    // right: 60px;
    top: 5px;
}

.menu_movil_home {
    display: none;
}

.page-dashboard {
    @media only screen and (max-width: 767px) {
        .menu_movil_home {
            display: block;
        }
        .hide_pc{
            display: none;
        }
    }
}

.dark-theme {
    @media only screen and (min-width: 768px) {
        .lateral {
            background: var(--ion-background-color);
        }
    }
}