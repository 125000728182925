.scroll_lista {
    height: 90%;
    overflow-y: scroll;
    width: 90%;
    display: flexbox;
    align-items: center;
    align-content: flex-end;
    //opacity: 0.9;
    margin: 3% 5% 7% 5%;
    border-radius: 20px;
    scrollbar-color: #15171d #0d0f13;
    .IonRow {
        border-bottom: 1px solid #ccc;
    }
    .IonRowMi {
        border-bottom: 1px solid #ccc;
        background-color: yellow;
    }
    .IonRowMi:last-child {
        margin-bottom: 100px;
    }
    .avatar1 {
        width: 64px;
    }
    .point {
        font-size: 36px;
        color: coral;
        text-align: center;
        padding-top: 20px;
    }
    .point_1 {
        color: green;
        font-weight: bolder;
    }
    .nick {
        font-size: 30px;
        color: var(--ion-color-primary);
    }
    .bien {
        color: green;
        font-weight: bolder;
    }
    .mal {
        color: red;
        font-weight: bolder;
    }
    .en_curso {
        //background-color: yellow;
        padding: 2px;
        color: rgb(186, 119, 4);
        font-weight: bolder;
    }
}

.end {
    text-align: right;
}

.finalizado {
    display: block;
}

.page-dashboard {
    @media only screen and (max-width: 767px) {
        .menu_movil_home {
            display: block;
        }
        .hide_pc {
            display: none;
        }
        .scroll_lista {
            overflow-y: scroll;
            display: flexbox;
            align-items: center;
            align-content: flex-end;
            margin: 0px;
            padding: 0px;
            border-radius: 0px;
            border: 0px;
            width: 100%;
            height: 100%;
            .avatar1 {
                width: 34px;
            }
            .point {
                font-size: 26px;
                color: coral;
                text-align: center;
                padding-top: 20px;
            }
            .point_1 {
                color: green;
                font-weight: bolder;
            }
            .nick {
                font-size: 20px;
                color: var(--ion-color-primary);
            }
            .acierto_errores_ranking {
                display: none;
            }
        }
        .centro_pagina {
            background: white;
            background-color: white;
        }
    }
}